<template>
<div class="ny_pad">
	<div class="menu_ico">
	 <top_menu></top_menu>
	 <!-- ho_menu -->
	 </div>
	<!-- menu_ico -->
	 <div class="gywm_lay1 content_lay">
	 
	   <div class="content_cont" style="padding-bottom: 50px; font-size: 16px; color: rgb(27, 27, 27); display: inline-block; width: 60%; text-align: left;">
	    <router-link v-if="lunboListe" :to="{path:'content',query:{id:item.id}}" v-for="(item, index) in lunboListe" :key="index">
	    <dl class="sousuo_list">
	    	<dt>
          <div style="float: left;">
            {{item.title}}
          </div>
          <div style="font-size: 12px;    color: #000;  font-weight: normal;  float: right;">
            {{(item.publishTime||'').slice(0,11).replace(/-/g,'/')}}
          </div>
        </dt>
	    	<dd  v-html="item.description"></dd>
	    </dl>
	    </router-link>

       <div v-if="lunboListe.length == 0 && codeList.length == 0 ">没有找到您所需的数据，请更换关键词再试！</div>
         <dl class="sousuo_list" v-for="(item, index) in codeList" :key="index"  v-if="codeList">
           <dt>{{item.name}}</dt>
           <dd v-if="item.type == 'text'">{{item.value}}</dd>
           <dd v-if="item.type == 'url'">
             <img  :src="item.value"  v-if="!(item.value!=null && item.value.endsWith('.pdf'))"/>
             <a :href="item.value" target="_blank" v-if="item.value!=null && item.value.endsWith('.pdf')">点击下载检验报告</a>
             <iframe style="width: 100%; height: 500px;" :src="item.value" v-if="!ism && item.value!=null && item.value.endsWith('.pdf')"></iframe>

             <img  :src="imgurls"  v-if="imgurls !='' && ism && item.name =='产品检验报告' "/>
           </dd>
           <dd v-if="item.type == 'text|url'">
             {{item.value.split('|')[0]}}
             <img  :src="item.value.split('|')[1]" style=""/></dd>
         </dl>

	   </div>
	  </div>
	  <!-- content_lay -->
	 <fo_oter></fo_oter>
	<!-- footer -->
</div>
</template>

<script>
import top_menu from '../components/menu.vue'
import fo_oter from '../components/footer.vue'
import pdf from 'vue-pdf'
import vueshowpdf from 'vueshowpdf'

import axios from 'axios'
import {GETLISTDATA} from "@/apis/api";
export default {
  name: 'searchlist',
  components: {
    top_menu,fo_oter,pdf,vueshowpdf
  },
  data() {
    return {
		title: this.$route.query.title,
      code: this.$route.query.code,
      pdfurls:"",
      imgurls:"",
      codeList:[],
      ism:false,
      isshowpdf:true,
		lunboListe:[],
      //, '检验报告'
      showList:['产品名称','质量标准' ,'标签' , '包装式样', '产品检验报告']
    };
  },
  mounted() {
    this.ism = this.isMobile();
    this.showlunbo();
  },
  methods:{
    closepdf(){
      this.isshowpdf = false
    },
    pdferr(errurl) {
      console.log(errurl);
    },
    isMobile() {
      let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    in_array(search,array){
      for(var i in array){
        if(array[i]==search){
          return i;
        }
      }
      return -1;
    },
	  showlunbo(){
      if(this.title){

        GETLISTDATA({catid:'',title:this.title,pageNum:1,pageSize:20}).then((res) => {
          if(res.code == 200){
            this.lunboListe = res.rows;
          }
        });

      }else if(this.code){
        let temThis = this;
        axios
            .get(
                //"https://qrzs-uat.huishandairy.com/86.1000.17/getInfobyBatch?code="+this.code,
                "https://qrzs.huishandairy.com/86.1000.17/getInfobyBatch?code="+this.code,
                // "http://localhost:8090/api/medic/user/test"
            )
            .then((res) => {
              if (res.status == 200 && res.data.length > 0 && res.data[0].result == "true") {
              // if (res.data.code == 200) {
              //   res.data = JSON.parse(res.data.data);
                let arry = [];
                let arryList = [];
                // this.codeList = res.data[0].产品信息;
                //let cpj = res.data[0].产品检验报告G;
                // let cpj1 = res.data[0].产品检验报告JPEG;
                arry = Array.prototype.concat.apply(arry, res.data[0].产品信息);
                arry = Array.prototype.concat.apply(arry, res.data[0].全产业链信息);
                arry = Array.prototype.concat.apply(arry, res.data[0].产品检验报告JPEG);
                for (let i=0;i<arry.length;i++){
                  if(arry[i].name == 'CPJCBG'){
                    arry[i].name = '检验报告';
                    this.imgurls = arry[i].value;
                  }
                  if(arry[i].name == '标准编码'){
                    arry[i].name = '质量标准';
                  }
                  if(arry[i].name == '自愿开放信息1'){
                    arry[i].name = '标签';
                  }
                  if(arry[i].name == '自愿开放信息2'){
                    arry[i].name = '包装式样';
                  }
                  let index = temThis.in_array(arry[i].name,temThis.showList);
                  if(index != -1){
                    arryList[index] = arry[i];
                    //arry.splice(i,1);
                  }
                }
                // console.log(arryList);
                this.codeList = arryList;
                //console.log(cpj1);
                //let cpj2 = res.data[0].全产业链信息;
                //this.codeList.push(cpj2)
              } else {
                alert("未获取到您要查找的信息，请检查产品编码是否正确。");
              }
            });
      }

	  }
  }
}
</script>
<style>
.content_cont img{
  max-width: 600px;
}
@media (max-width: 768px) {
  .content_cont img{
    max-width: 100%;
  }
}
</style>